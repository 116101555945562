import React from 'react';

const DataImportExport = ({ months, setMonths }) => {
    const fileInputRef = React.createRef();

    const handleExport = () => {
        const dataStr = JSON.stringify(months, null, 2);
        const dataBlob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.download = 'budget_data.json';
        link.href = url;
        link.click();
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/json') {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const parsedData = JSON.parse(e.target.result);
                    setMonths(parsedData);
                    alert('Data importert!');
                } catch (error) {
                    alert('Kunne ikke importere data');
                }
            };
            reader.readAsText(file);
        } else {
            alert('Kunne ikke importere data');
        }
    };

    return (
        <div className='p-4'>
            <button onClick={handleExport} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                Eksporter data
            </button>
            <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept='.json'
                style={{ display: 'none' }}
            />
            <button
                onClick={() => fileInputRef.current.click()}
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2'
            >
                Importer data
            </button>
        </div>
    )
}

export default DataImportExport