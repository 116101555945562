import React from 'react'

const ExpenseList = ({ expenses, removeExpense }) => {
    return (
        <div className='p-4'>
            <h2 className='text-lg font-bold mb-4'>Utgifter & Inntekt</h2>
            <ul>
                {expenses.map((expense, index) => (
                    <li key={index} className={`flex justify-between font-semibold items-center p-4 rounded-xl mb-2 ${expense.type === 'expense' ? 'bg-red-200' : 'bg-green-200'}`}>
                        <span>{expense.name}: Kr {expense.amount.toFixed(2)},-</span>
                        <button
                            onClick={() => removeExpense(index)}
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-md focus:outline-none focus:shadow-outline'
                        >
                                X
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ExpenseList