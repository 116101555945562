import React from 'react'

const BalanceDisplay = ({ balance }) => {
    return (
        <div className='p-4'>
            <h2 className='text-lg font-bold mb-4'>Saldo: Kr {balance.toFixed(2)},-</h2>
        </div>
    )
}

export default BalanceDisplay