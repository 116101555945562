import React from 'react';

const MonthSelector = ({ months, activeMonth, setActiveMonth, addNewMonth }) => {
    return (
        <div className='p-4'>
            <select
                value={activeMonth}
                onChange={(e) => setActiveMonth(e.target.value)}
                className='mr-4 p-2 border rounded'
            >
                {months.map((month, index) => (
                    <option key={index} value={index}>{month.name}</option>
                ))}
            </select>
            <button onClick={addNewMonth} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                Ny måned
            </button>
        </div>
    )
}

export default MonthSelector