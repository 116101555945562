import React from 'react'

const SalaryInput = ({ salary, onSalaryChange }) => {
    return (
        <div className='p-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='salary'>
                Måneds lønn:
            </label>
            <input
                type='number'
                id='salary'
                value={salary}
                onChange={(e) => onSalaryChange(e.target.value)}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
        </div>
    )
}

export default SalaryInput