import React, { useState } from 'react';

const ExpenseForm = ({ addExpense }) => {
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');

    const handleSubmit = (e, type) => {
        e.preventDefault();
        if (amount && name) {
            addExpense({ name, amount: parseFloat(amount), type });
            setName('');
            setAmount('');
        }
    };

    return (
        <form className='p-4' onSubmit={handleSubmit}>
            <div className='mb-4'>
                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='expense-name'>
                    Utgift/Inntekt navn:
                </label>
                <input 
                    type='text'
                    id='expense-name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                />
            </div>
            <div className='mb-4'>
                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='expense-amount'>
                    Beløp:
                </label>
                <input 
                    type='number'
                    id='expense-amount'
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                />
            </div>
            <button type='button' className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2' onClick={(e) => handleSubmit(e, 'expense')}>
                Utgift
            </button>
            <button type='button' className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={(e) => handleSubmit(e, 'income')}>
                Inntekt
            </button>
        </form>
    )
}

export default ExpenseForm