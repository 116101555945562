import React, { useState, useEffect } from 'react';
import SalaryInput from './components/SalaryInput';
import ExpenseForm from './components/ExpenseForm';
import ExpenseList from './components/ExpenseList';
import BalanceDisplay from './components/BalanceDisplay';
import MonthSelector from './components/MonthSelector';
import DataImportExport from './components/DataImportExport';
import './App.css';

function App() {
    const [months, setMonths] = useState(JSON.parse(localStorage.getItem('months')) || []);
    const [activeMonth, setActiveMonth] = useState(0);

    useEffect(() => {
        localStorage.setItem('months', JSON.stringify(months));
    }, [months]);

    const addNewMonth = () => {
        const newMonth = {
            name: prompt('Enter the name of the new month:'),
            salary: '',
            expenses: [],
        };
        setMonths([...months, newMonth]);
        setActiveMonth(months.length);
    };

    const setSalary = (salary) => {
        const newMonths = [...months];
        newMonths[activeMonth].salary = salary;
        setMonths(newMonths);
    };

    const addTransaction = (transaction) => {
        const newMonths = [...months];
        newMonths[activeMonth].expenses.push(transaction);
        setMonths(newMonths);
    };

    const removeExpense = (index) => {
        const newMonths = [...months];
        newMonths[activeMonth].expenses.splice(index, 1);
        setMonths(newMonths);
    };

    const currentMonth = months[activeMonth] || { name: '', salary: '', expenses: [] };
    const totalIncome = currentMonth.expenses.reduce((acc, transaction) => transaction.type === 'income' ? acc + transaction.amount : acc, parseFloat(currentMonth.salary));
    const totalExpenses = currentMonth.expenses.reduce((acc, transaction) => transaction.type === 'expense' ? acc + transaction.amount : acc, 0);
    const balance = totalIncome - totalExpenses;

    return (
        <div className='container mx-auto p-4'>
            <MonthSelector months={months} activeMonth={activeMonth} setActiveMonth={setActiveMonth} addNewMonth={addNewMonth} />
            <SalaryInput salary={currentMonth.salary} onSalaryChange={setSalary} />
            <ExpenseForm addExpense={addTransaction} />
            <ExpenseList expenses={currentMonth.expenses} removeExpense={removeExpense} />
            <BalanceDisplay balance={balance} />
            <DataImportExport months={months} setMonths={setMonths} />
        </div>
    );
}

export default App;
